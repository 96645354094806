<template>
  <el-main class="register-container">
    <div class="register-inner inner-container">
      <section class="title-section">
        <div class="section-h2">
          <MainContain />
          {{ $t('Register.JoinUs') }}
        </div>
        <div>
          {{ $t('Register.WithUs') }}
        </div>
      </section>
      <div class="desk-pad-view">
        <el-row :gutter="20" class="register-row" type="flex" justify="center">
          <el-col v-for="item in register" :key="item.title" class="register-col">
            <ApplyCard :item="item" />
          </el-col>
        </el-row>
      </div>
      <div class="phone-view">
        <el-row v-for="item in register" :key="item.title" class="register-row">
          <ApplyCard :item="item" />
        </el-row>
      </div>  
    </div>
  </el-main>
</template>

<script>
import ApplyCard from './components/ApplyCard.vue'
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'RegisterPage',
  components: {
    ApplyCard,
    MainContain
  },
  data () {
    return {
      register: [
        {
          title: 'provider',
          titleText: this.$t('Register.DonateData'),
          type: 'provider',
          routerName: 'ProviderForm',
          typeStyle: 'provider-yellow',
          picsrc: require('@/assets/images/provider.png')
        },
        {
          title: 'consumer',
          titleText: this.$t('Register.UseData'),
          type: 'consumer',
          routerName: 'ConsumerForm',
          typeStyle: 'consumer-turquoise',
          picsrc: require('@/assets/images/consumer.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.register-container {
  min-height: calc(100vh - 360px);
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .register-inner{
    padding: 1rem 0;
    @media (min-width: 1281px){
      padding: 2rem 0;
    }
    .title-section{
      padding: 1rem 0 1.5rem 0;
      text-align: center;
    }
    .desk-pad-view{
      @include hidden-by-pad;
      @include hidden-by-phone;
    }
    .phone-view{
      @include hidden-by-desk;
      
      .register-row{
        margin: 1rem auto 2.5rem;
        width: 300px;
      }
    }
    .register-row{
      
      .register-col{
        width: 330px;
      }
    }
  }
  @media (max-width: 768px) {
    .register-inner{
      padding: 2rem 0;
    }
  }
}

</style>
