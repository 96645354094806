<template>
  <el-card :body-style="{ padding: '0px' }">
    <div class="image-conatiner" aria-hidden="true">
      <img :src="item.picsrc" class="image" aria-hidden="true" alt="">
    </div>
    <div class="card-head">
      <div class="section-h3">
        {{ item.titleText }}
      </div>
    </div>
    <div class="card-footer">
      <el-button 
        type="button"
        :class="item.typeStyle"
        @click="handleRouter(item.routerName)"
      >
        {{ $t('general.Be') }} {{ $t(`Organization.${item.title}`) }}
      </el-button>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'ApplyCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleRouter(routerName) {
      this.$router.push({ name: routerName })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
  .image-conatiner{
      height: 200px;
      padding: 30px 10px;
      .image {
        margin: 0 auto;
        width: 70%;
        display: block;
      }
    }

    .provider-yellow{
      background-color: $sport_yellow;
    }

    .consumer-turquoise{
      background-color: $physicalFitness_turquoise;
    }
    .provider-yellow:hover{
      border:1px solid $sport_yellow;
    }

    .consumer-turquoise:hover{
      border:1px solid $physicalFitness_turquoise;
    }

    .card-head{
      text-align: center;
      .section-h3{
        margin: 0;
        line-height: 3.75rem;
      }
    }
    .card-body{
      line-height: 1.5;
      padding: 1rem 1.5rem;

    }
    .card-footer{
      display: flex;
      justify-content: center;    
      padding-bottom: 1.5rem; 
    }
    .el-button{
      padding: 0.75rem 2.5rem;
      color: $text_dark;
    }
    .el-button:hover{
      color:$background_dark;
      background-color: $text_light;
      font-size: bold;
    }
</style>
